/* eslint react/prop-types: 0 */
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import ContactFormContainer from "../../common/ContactFormContainer";
import Layout from "./components/layout";
import SEO from "./components/seo";
import {
  getTranslatedValue,
  translateStatic,
} from "../../helpers/translationHelper";
import * as theme2Styles from "../theme2/scss/theme2.module.scss";

import Media from "./detail/common/Media";
import Feature from "./detail/common/Feature";
import Image from "./detail/common/Image";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import Layouts from "./detail/Layouts";
import PropertyList from "./components/property-list";
import OpenStreetMap from "../../common/OpenStreetMap";

const Theme2BuildingDetails = (props) => {
  const { pageContext, result } = props;
  const { building, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: { name: siteName, setting: display_settings },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_project_layouts,
    display_project_listings,
    display_project_location_map,
    display_watermark,
  } = display_settings;

  const headline = getTranslatedValue(
    building.translations,
    "headline",
    locale,
    defaultLocale
  );
  const description = getTranslatedValue(
    building.translations,
    "description",
    locale,
    defaultLocale
  );

  let seoTitle = `${building.name}`;
  if (headline) {
    seoTitle = `${headline}-${building.name}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />

      <div className={`${theme2Styles.detailsHeaderC}`}>
        <div className={theme2Styles.wrapper}>
          <div className={`${theme2Styles.detailsHeader}`}>
            <div className={theme2Styles.hLeft}>
              <h2>{building.name}</h2>
              <span className={theme2Styles.loc}>{building.location}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={`${theme2Styles.detailsBannerWithForm}`}>
        {(() => {
          if (building.pictures.length > 0) {
            return (
              <Media
                pictures={building.pictures}
                watermark={display_watermark}
              />
            );
          }
        })()}
        <div className={theme2Styles.wrapper}>
          <div className={`${theme2Styles.formArea}`}>
            <div className={`${theme2Styles.contactUs}`}>
              <h6>
                {translateStatic("contact_us", locale, defaultLocale)}
                <small>
                  {translateStatic("contact_form_text", locale, defaultLocale)}
                </small>
              </h6>
              <ContactFormContainer />
            </div>
          </div>
        </div>
      </div>

      <div className={`${theme2Styles.mainContent} ${theme2Styles.pT70}`}>
        <div className={theme2Styles.wrapper}>
          {(() => {
            if (building.services.length > 0 || building.amenities.length > 0) {
              return (
                <div className={`${theme2Styles.propDetailsSec}`}>
                  <Feature
                    services={building.services}
                    amenities={building.amenities}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (building.translations.length > 0 && description) {
              if (building.translations[0].description.trim() != "") {
                return (
                  <div className={`${theme2Styles.propDetailsSec}`}>
                    <h3 dangerouslySetInnerHTML={{ __html: headline }}></h3>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  </div>
                );
              }
            }
          })()}

          {(() => {
            if (building.pictures.length > 0) {
              return (
                <div className={`${theme2Styles.propDetailsSec}`}>
                  <Image
                    pictures={building.pictures}
                    watermark={display_watermark}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (building.videos.length > 0) {
              return (
                <div className={`${theme2Styles.propDetailsSec}`}>
                  <Video
                    videos={building.videos}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (building.documents.length > 0) {
              return (
                <div className={`${theme2Styles.propDetailsSec}`}>
                  <Document
                    documents={building.documents}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (display_project_layouts && building.layouts.length > 0) {
              return (
                <div className={theme2Styles.propDetailsSec}>
                  <Layouts
                    layouts={building.layouts}
                    locale={locale}
                    defaultLocale={defaultLocale}
                    watermark={display_watermark}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (display_project_listings && building.properties.length > 0) {
              return (
                <div className={theme2Styles.propDetailsSec}>
                  <h3>
                    {translateStatic("properties", locale, defaultLocale)}
                  </h3>
                  <PropertyList
                    properties={building.properties.slice(0, 4)}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                  <div
                    className={`${theme2Styles.mt16} ${theme2Styles.textRight}`}
                  >
                    <Link
                      to={`/${locale}/building/${building.uuid}/properties`}
                      className={`${theme2Styles.btn} ${theme2Styles.btnPrimary} ${theme2Styles.btnSm}`}
                    >
                      {translateStatic(
                        "view_all_properties",
                        locale,
                        defaultLocale
                      )}
                    </Link>
                  </div>
                </div>
              );
            }
          })()}

          {display_project_location_map && building.latitude && building.longitude && 
          <div className={`${theme2Styles.propDetailsSec}`} style={{ height: '300px' }}>
            <OpenStreetMap 
              listingType="building" 
              listings={[building]} 
              latitude={parseFloat(building.latitude)} 
              longitude={parseFloat(building.longitude)} 
              zoom={12} 
              infoWindow={false} 
              moveEvent={false}
            />
          </div>}
        </div>
      </div>
    </Layout>
  );
};

export default Theme2BuildingDetails;

Theme2BuildingDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
