import React from "react";
import PropTypes from "prop-types";

import ImageModal from "../../../../common/ImageModal";
import { translateStatic } from "../../../../helpers/translationHelper";
import * as theme5Styles from "../../scss/theme5.module.scss";

const Image = ({ pictures, watermark, locale, defaultLocale }) => {
  return (
    <>
      <h3>{translateStatic("pictures", locale, defaultLocale)}</h3>
      <ImageModal
        themeStyles={theme5Styles}
        images={pictures}
        watermark={watermark}
      />
    </>
  );
};

Image.propTypes = {
  pictures: PropTypes.array.isRequired,
  watermark: PropTypes.bool,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
};

export default Image;
