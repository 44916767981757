/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { formatPrice } from "../../../helpers/propertyHelper";
import { getTranslatedValue } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";
import * as theme5Styles from "../scss/theme5.module.scss";

import property1 from "../images/property-image-1.jpg";
import bed from "../images/bed.svg";
import bathtub from "../images/bathtub.svg";
import totalArea from "../images/total-area.svg";
import builtArea from "../images/built-area.svg";
import tagicon from "../images/tag.png";

const PropertyList = ({ properties, locale, defaultLocale }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          company: { currency: company_currency },
        },
      },
    },
  } = siteData;

  return (
    <div className={`${theme5Styles.productRow}`}>
      {properties.map((property) => {
        const {
          pictures,
          category,
          property_type,
          measurement_unit,
          translations,
        } = property;

        const picture =
          pictures.length > 0
            ? prepareImageURL(pictures[0], 480, 320, false)
            : property1;

        return (
          <div key={property.uuid} className={theme5Styles.colm}>
            <div className={`${theme5Styles.productBox}`}>
              <Link
                to={`/${locale}/property/${property.uuid}`}
                className={theme5Styles.image}
              >
                <img alt="property" src={picture} />
                <span className={`${theme5Styles.price}`}>
                  <span className={`${theme5Styles.tag}`}>
                    <img src={tagicon} allt="" />
                  </span>
                  {formatPrice(property, company_currency)}
                </span>
              </Link>
              <div className={theme5Styles.details}>
                <h4
                  dangerouslySetInnerHTML={{
                    __html:
                      getTranslatedValue(
                        translations,
                        "headline",
                        locale,
                        defaultLocale
                      ) || "NA",
                  }}
                ></h4>
                <div className={`${theme5Styles.category}`}>
                  <span className={`${theme5Styles.catg}`}>
                    {(() => {
                      const propertyType = property_type
                        ? getTranslatedValue(
                            property_type.translations,
                            "name",
                            locale,
                            defaultLocale
                          )
                        : null;
                      const propertyCategory = category
                        ? getTranslatedValue(
                            category.translations,
                            "name",
                            locale,
                            defaultLocale
                          )
                        : null;

                      if (propertyType && !propertyCategory) {
                        return propertyType;
                      }
                      if (!propertyType && propertyCategory) {
                        return propertyCategory;
                      }
                      if (propertyType && propertyCategory) {
                        return `${propertyType} for ${propertyCategory}`;
                      }
                    })()}
                  </span>
                  <span className={`${theme5Styles.uuid}`}>
                    {property.reference}
                  </span>
                </div>
                <p>{property.location}</p>
                <div className={theme5Styles.features}>
                  <div className={theme5Styles.li}>
                    <img alt="bed" src={bed} />
                    <span>{property.bedrooms} Beds</span>
                  </div>
                  <div className={theme5Styles.li}>
                    <img alt="bed" src={bathtub} />
                    <span>{property.bathrooms} Baths</span>
                  </div>
                  <div className={`${theme5Styles.li} ${theme5Styles.sm}`}>
                    <img alt="image" src={totalArea} />
                    <span>
                      {property.internal_area_value}{" "}
                      {measurement_unit ? measurement_unit.name : ""} Total
                    </span>
                  </div>
                  <div className={`${theme5Styles.li} ${theme5Styles.sm}`}>
                    <img alt="image" src={builtArea} />
                    <span>
                      {property.internal_area_value}{" "}
                      {measurement_unit ? measurement_unit.name : ""} Built
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PropertyList;
