/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { formatPrice } from "../../../helpers/propertyHelper";
import { getTranslatedValue } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";
import * as theme2Styles from "../scss/theme2.module.scss";

import property1 from "../images/property-image-1.jpg";
import bed from "../images/bed.svg";
import bathtub from "../images/bathtub.svg";
import swimming from "../images/swimming-pool.svg";

const PropertyList = ({ properties, locale, defaultLocale }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          company: { currency: company_currency },
        },
      },
    },
  } = siteData;

  return (
    <div className={`${theme2Styles.productRow}`}>
      {properties.map((property) => {
        const { pictures, property_type, category, translations } = property;

        const picture =
          pictures.length > 0
            ? prepareImageURL(pictures[0], 480, 320, false)
            : property1;

        return (
          <div className={theme2Styles.colm} key={property.uuid}>
            <div className={`${theme2Styles.productBox}`}>
              <Link
                to={`/${locale}/property/${property.uuid}`}
                className={theme2Styles.image}
              >
                <img alt="image" src={picture} />
                {(() => {
                  if (property_type && property_type.translations.length > 0) {
                    const propertyType = getTranslatedValue(
                      property_type.translations,
                      "name",
                      locale,
                      defaultLocale
                    );
                    if (propertyType) {
                      return (
                        <span className={theme2Styles.type}>
                          {propertyType}
                        </span>
                      );
                    }
                  }
                })()}
              </Link>
              <div className={theme2Styles.details}>
                <div className={theme2Styles.top}>
                  <h3>
                    <Link to={`/${locale}/property/${property.uuid}`}>
                      {property.reference}
                    </Link>
                  </h3>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html:
                        getTranslatedValue(
                          translations,
                          "headline",
                          locale,
                          defaultLocale
                        ) || "",
                    }}
                  ></h4>
                  <p>{property.location}</p>
                  <div className={`${theme2Styles.priceC}`}>
                    {(() => {
                      if (category && category.translations.length > 0) {
                        const propertyCategory = getTranslatedValue(
                          category.translations,
                          "name",
                          locale,
                          defaultLocale
                        );
                        if (propertyCategory) {
                          return (
                            <span className={theme2Styles.category}>
                              <span>{propertyCategory}</span>
                            </span>
                          );
                        }
                      }
                    })()}
                    <span className={theme2Styles.price}>
                      {formatPrice(property, company_currency)}
                    </span>
                  </div>
                </div>
                <div className={theme2Styles.bottom}>
                  <div className={theme2Styles.features}>
                    <div className={theme2Styles.li}>
                      <img alt="image" src={bed} />
                      <span>{property.bedrooms}</span>
                    </div>
                    <div className={theme2Styles.li}>
                      <img alt="image" src={bathtub} />
                      <span>{property.bathrooms}</span>
                    </div>
                    <div className={theme2Styles.li}>
                      <img alt="image" src={swimming} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default PropertyList;
