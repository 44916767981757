import _ from "lodash";

export const calculateBedroomsRange = (layouts = []) => {
  if (layouts.length == 0) {
    return 0;
  }

  let min = _.min(layouts, (layout) => layout.bedroom);

  let max = _.max(layouts, (layout) => layout.bedroom);

  return `${min.bedroom}-${max.bedroom}`;
};

export const calculateBathroomsRange = (layouts = []) => {
  if (layouts.length == 0) {
    return 0;
  }

  let min = _.min(layouts, (layout) => layout.bathroom);

  let max = _.max(layouts, (layout) => layout.bathroom);

  return `${min.bathroom}-${max.bathroom}`;
};
