/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { formatPrice } from "../../../helpers/propertyHelper";
import { getTranslatedValue } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";
import * as theme3Styles from "../scss/theme3.module.scss";

import property1 from "../images/property-image-1.jpg";

const PropertyList = ({ properties, locale, defaultLocale }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          company: { currency: company_currency },
        },
      },
    },
  } = siteData;

  return (
    <div className={`${theme3Styles.productRow}`}>
      {properties.map((property) => {
        const {
          pictures,
          property_type,
          category,
          measurement_unit,
          translations,
        } = property;

        const picture =
          pictures.length > 0
            ? prepareImageURL(pictures[0], 480, 320, false)
            : property1;

        return (
          <div className={theme3Styles.colm} key={property.uuid}>
            <div className={`${theme3Styles.productBox}`}>
              <Link
                to={`/${locale}/property/${property.uuid}`}
                className={theme3Styles.image}
              >
                <img src={picture} alt="" />
                {(() => {
                  if (property_type && property_type.translations.length > 0) {
                    return (
                      <span className={theme3Styles.type}>
                        {getTranslatedValue(
                          property_type.translations,
                          "name",
                          locale,
                          defaultLocale
                        )}
                      </span>
                    );
                  }
                })()}
                <span className={theme3Styles.uuid}>{property.reference}</span>
              </Link>
              <div className={theme3Styles.details}>
                <div className={`${theme3Styles.priceC}`}>
                  <span className={theme3Styles.price}>
                    {formatPrice(property, company_currency)}
                  </span>
                  <ul className={theme3Styles.features}>
                    <li>
                      {property.bedrooms}
                      <abbr> bds</abbr>
                    </li>
                    <li>
                      {property.bathrooms}
                      <abbr> ba</abbr>
                    </li>
                    <li>
                      {property.internal_area_value}
                      <abbr>
                        {" "}
                        {measurement_unit ? measurement_unit.name : ""}
                      </abbr>
                    </li>
                  </ul>
                </div>
                <h4
                  dangerouslySetInnerHTML={{
                    __html:
                      getTranslatedValue(
                        translations,
                        "headline",
                        locale,
                        defaultLocale
                      ) || "",
                  }}
                ></h4>
                <h5>{property.location}</h5>
                <span className={theme3Styles.category}>
                  {(() => {
                    const propertyType = property_type
                      ? getTranslatedValue(
                          property_type.translations,
                          "name",
                          locale,
                          defaultLocale
                        )
                      : null;
                    const propertyCategory = category
                      ? getTranslatedValue(
                          category.translations,
                          "name",
                          locale,
                          defaultLocale
                        )
                      : null;

                    if (propertyType && !propertyCategory) {
                      return propertyType;
                    }
                    if (!propertyType && propertyCategory) {
                      return propertyCategory;
                    }
                    if (propertyType && propertyCategory) {
                      return `${propertyType} for ${propertyCategory}`;
                    }
                  })()}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PropertyList;
